import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Widget from './style'
import img from "~assets/image/customer-stories/feature-story-2.png";
import { SuperTag, Link } from '~components';
import VideoJSFour from '../Videojs/VideoJSFour';

export default function RscWidget(){
  
return(
      
<Widget>
  <Container>
  <Widget.Block>
    <Row className=" justify-content-lg-start">
    <Col xs="12" className="col-xl-5 col-lg-5 col-md-8 col-xs-10">
    <Widget.Image>
    <VideoJSFour videoId="vyaos7fab1" wrapper="wistia-player-container-1"/>
    </Widget.Image>
      </Col>
    <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
        <Widget.Box>
        <Link to="/customer-stories"><Widget.MiniHeading><Widget.BackButton textColor="#000"><i class='fas fa-angle-left'></i>  Customer Stories</Widget.BackButton> </Widget.MiniHeading></Link>
          <Widget.Heading as="h2"> <SuperTag value="RSC Sales Booked 5x Meetings For a Client With We-Connect"/> </Widget.Heading>
        </Widget.Box>
      </Col>
    </Row>
    </Widget.Block>

    <Widget.Block>
    <Row className="justify-content-lg-start">
    <Col xs="12" className="col-xl-4 col-lg-4 col-md-8 col-xs-10">
        <Widget.Card>
        <Widget.Company as="h2">RSC Sales</Widget.Company>
        <Widget.Text>RSC Sales is a consulting firm covering all angles of the sales process. With a focus on customer retention and acquisition, RSC Sales works with clients to take their business to the next level.</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara className="rsc"> <b>Industry</b> </Widget.SubPara>
        <Widget.Text>Consulting</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara className="rsc"> <b>Company Size</b> </Widget.SubPara>
        <Widget.Text> 2-10 employees</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara className="rsc"> <b>Features Used</b> </Widget.SubPara>
        <Widget.Text>Smart sequences, automated celebration messages, reporting and analytics.</Widget.Text>
        </Widget.Card>
      </Col>
    <Col className="col-xl-7 col-lg-7 col-md-8 col-xs-10">

        <Widget.Title as="h2"><b>Generate More Conversations – Make Better Informed Decisions</b></Widget.Title>
        <Widget.Text>Aurelia Jusyte owns three businesses. One of these businesses is RSC Sales, a consulting firm providing clients with an end-to-end lead-generation service. Aurelia and her team leverage We-Connect to automate their prospecting and save time when using LinkedIn.
        </Widget.Text>
        <Widget.Text>
        “We-Connect is such a great tool for our new initiatives and projects,” Aurelia explained to us. She and her team use it for multiple use cases – not just to find new leads.
        </Widget.Text>
        <Widget.Text>
        One of these use cases is user testing. Aurelia shared, “Our clients don’t know how their market is going to react to their product, so we use We-Connect to message as many people on LinkedIn.” This gives the RSC sales team a real look at how individuals feel about a new offer – enabling them to better tailor their marketing strategy for that client.
        </Widget.Text>
        <Widget.SubPara>
        Watch our entire video interview above to learn more about how RSC Sales leverages We-Connect to maximize their client’s lead-generation:
        </Widget.SubPara>

        <Widget.CardTwo>
        <Widget.SubPara> Start your free trial and start kicking butt like this company did.</Widget.SubPara>
        <Widget.Button target="_blank" to="//account.we-connect.io/signup" className="btn btn-primary text-white">14 day free trial</Widget.Button>
        </Widget.CardTwo>
      </Col>
    </Row>
    </Widget.Block>
    
  </Container>
</Widget>

)
}