import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import { Helmet } from "react-helmet";
import RscWidget from "~sections/featured-story/components/CS-widget/rsc"
//import scrollTo from 'gatsby-plugin-smoothscroll';
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function FeaturedStoryFour() {
  return (
    <>
    <Helmet>
    <title>RSC Sales Booked 5x Meetings For a Client With We-Connect</title>
    <meta name="description" content="Aurelia Jusyte, founder of RSC Sales, talks about how she uses We-Connect to expand her client’s prospecting efforts, networking, and meetings booked."/>
    <meta property="og:title" content="RSC Sales Booked 5x Meetings For a Client With We-Connect "/>
    <meta property="og:description" content="Aurelia Jusyte, founder of RSC Sales, talks about how she uses We-Connect to expand her client’s prospecting efforts, networking, and meetings booked."/>
    <meta property="og:image" content="https://we-connect.io/rsc-sales-booked-5x-meetings-with-we-connect.png"/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://we-connect.io/rsc-sales"/>
    <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
    <meta property="twitter:title" content="RSC Sales Booked 5x Meetings For a Client With We-Connect "/>
    <meta property="twitter:description" content="Aurelia Jusyte, founder of RSC Sales, talks about how she uses We-Connect to expand her client’s prospecting efforts, networking, and meetings booked."/>
    <meta property="twitter:image" content="https://we-connect.io/rsc-sales-booked-5x-meetings-with-we-connect.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <RscWidget />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}